var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"windows",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","lg":"8","xl":"6"}},[_c('v-card',{staticClass:"elevation-5 mt-5"},[_c('v-tabs',{attrs:{"grow":""}},[_c('v-tab',[_vm._v("Disqus")]),_c('v-tab',[_vm._v("Cusdis")]),_c('v-tab-item',{staticClass:"pa-5"},[_c('Disqus',{ref:"disqus",attrs:{"shortname":_vm.disqus_shortname,"pageConfig":{
              url: `${_vm.url}/?search=${_vm.item.title}`,
              identifier: `${_vm.item.id}`,
              title: `${_vm.item.id}. ${_vm.item.title}`
            }}})],1),_c('v-tab-item',{staticClass:"pa-5"},[_c('vue-cusdis',{key:_vm.item.id,attrs:{"attrs":{
              host: _vm.cusdis_appId,
              appId: _vm.cusdis_pageId,
              pageId: `${_vm.item.id}`,
              pageTitle: `${_vm.item.id}. ${_vm.item.title}`,
              pageUrl: `${_vm.url}/?search=${_vm.item.title}`,
              theme: _vm.theme()
            }}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }